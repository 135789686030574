.about .why-us-element-text {
    max-width: 500px;
    margin-right: 50px;
    text-align: left;
}

.about .why-us {
    margin: 0 30px;
}

.about .why-us-left-title {
    text-align: left;
}

.about-team img {
    max-width: 500px;
    width:100%;
}

.our-team {
    padding-top:  50px;
}
@media (min-width: 1200px) {
    .about-team img {
        margin:0 50px
    }
}

@media (min-width: 800px) and (max-width: 950px) {
    .about-team img {
        max-width: 400px;
    }
}


@media (min-width: 800px) {
    .about .why-us {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
   
}

@media (max-width: 799px) {
    .about .why-us-left {
        text-align: center;
    }

    .about .why-us-left-title, .about .why-us-element-text {
        width: 400px;
        margin-right: 50px;
    }

    .about .center-wrapper {
        display: flex;
        justify-content: center;
    }
   
}
