.slider {
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    color:white;
    display: block !important;
    text-align: left;
}

.slider-1 {
    background-image: url('../../../../public/img/slider1.jpg');
}

.slider-2 {
    background-image: url('../../../../public/img/slider3.jpg');
}

.slider-3 {
    background-image: url('../../../../public/img/slider2.jpg');
}

.slider-header {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.silder-title {
    font-size: 60px;
    line-height: 72px;
    font-weight: 900;
    margin-bottom: 40px;
}

.slider-button {
    background: #f6bb18;
    border: 1px solid #f6bb18;
    color: #021e3c;
    font-size: 14px;
    line-height: 21px;
    padding: 14px 25px;
    border-radius: 40px;
}

.slider-button:hover {
    background: none;
    color: #f6bb18;
}

.slider {
    display: flex !important;
    justify-content: center;
    align-items: center;
}



@media (min-width: 1024px) {
  
    .slider-wrapper {
        width: 980px !important;
    }
    
  }

@media (min-width: 675px) and (max-width: 1023px) {

    .slider-wrapper {
        width: 670px !important;
    }
    .silder-title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 30px;
    }

}

@media (max-width: 674px) {
  
    .slider-wrapper {
        text-align: center;
    }
    .silder-title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 30px;
    }
    
  }