.pageHeader {
    text-align: center;
    background-image: url('/public/img/bg_1.jpg');
    height: 300px;
    background-position: center;
    color:#fff;
};

.pageHeader-title {
    font-weight: 900;
    color:red
}

.pageHeader-url a:hover {
    color: #f6bb18 !important;
}