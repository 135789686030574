nav {
    /* position: fixed;
    top: 0; */
    width: 100%;
    height: 64px;
    background: #0d1128;
    /* background: linear-gradient(45deg, rgb(156, 14, 156), midnightblue); */
}

nav ul {
    z-index: 1;
}



.list {
    /* padding: 0; */
    list-style-type: none;
    background: #0d1128;
    /* background: linear-gradient(45deg, rgb(156, 14, 156), midnightblue); */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.items {
    margin-right: 40px;
    font-size: 14px;
    color: #f1f1f1;
    cursor: pointer;
}

.items a:hover {
    color:#f6bb18;
}

.btn {
    display: none;
    /* position: absolute; */
    /* right: 10px;
    top: 7px; */
    padding: 5px;
    color: #fff;
    font-size: 18px;
    background: none;
    border:none
}

.items a {
    color:white;
}

.btn-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    margin-left: 20px;
}




@media screen and (max-width: 675px){

    .list {
        flex-direction: column;
        height: auto;
        background: #233e62;
    }

    nav {
        background: #233e62;
        height: 47px
    }
    /* .items:nth-child(1){
        border-top: 1px solid rgba(255, 255, 255, 0.555);
        margin-top: 50px;
    } */
    .items {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.555);
        text-align: center;
        margin-right: 0px;
        padding: 20px 0;
    }
    .btn {
        display: block;
    }

}

@media screen and (min-width: 1024px){
ul {
    padding: 0 480px 0 0;
}
}
  
  @media (min-width: 675px) and (max-width: 1023px){
    ul {
        padding: 0 180px 0 0;
    }
}