.footer{
    background: #021e3c;
}

.footer-main {
    padding: 70px 0;
}

.footer-bottom {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 80px 10px;
    text-align: center;
}

.footer-element a {
    color: #f6bb18;
    font-size: 14px;
}

.link-text {
    color: rgba(255, 255, 255, 0.7);
    margin-left: 5px;
}

.link-text:hover {
    color: #f6bb18;
}

.a-content {
    margin-bottom: 15px;
}

.footer-element-content {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 15px;
}

.footer-element-header {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 35px;
}


.footer-element-content-link {
    margin:0;
}

.footer-element {
    margin: 0 20px;
}

.footer-element-content input {
    background: rgba(255, 255, 255, 0.1);
    border:none;
    text-align: center;
    color: rgba(255, 255, 255, 0.7) !important;
    width: 245px;
    height: 50px;
}

.footer-element-content button {
    background: #f6bb18 ;
    border:none;
    font-size: 16px;
    color: #021e3c; 
    border-radius: 2px;
    text-align: center;
    width: 250px;
    height: 50px;
}

.footer-element-content button:hover {
    background:#1b9ce3 ;
}

.footer-element-content-social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.social-icons {
    margin-right: 12px;
}


@media (min-width: 1056px) {
    .footer-main {
        display: flex;
        justify-content: center;
        align-items:flex-start;
    }
}

@media (max-width: 1055px) {
    .footer-main-side-bottom {
        margin-top: 50px;
    } 
}

@media (min-width: 512px) {
    .footer-main-side {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    } 

}

@media (max-width: 511px) {

    .footer-element-header {
        margin-top: 30px;
    }

}

@media (min-width: 380px) {

    .footer-main-side {
        margin: 0 40px
    } 

}