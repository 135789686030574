.why-us {
    padding: 100px 0;
}

.home .why-us-left {
    width:fit-content
}

.why-us-right {
    padding: 20px;
    border: 4px solid #021e3c;
}
.why-us-element {
    width: 210px;
    text-align: center;
    padding: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
}

.why-us-left-title {
    font-weight: 700;
    color: #233e62;
    font-size: 30px;
    margin-bottom: 40px;
}


.why-us-element-title {
    font-weight: 700;
    font-size: 20px;
    color: #233e62;
    margin-bottom: 10px;
}

.why-us-element-text {
    font-family: "Nunito Sans", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: gray;
}

.white-element {
    color:white;
}

.why-us-element {
    height: 300px;
    background: #f0f9fd;
    position: relative;
  }
  .why-us-element:hover{
    background-color: #021e3c;
  }
  .why-us-element:hover:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 45px solid white;
    border-left: 55px solid #021e3c;
    width: 0;
  }
  .why-us-element:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 45px solid white;
    border-left: 55px solid #f0f9fd;
    width: 0;
  }

  .about-button {
    margin-bottom: 30px;
  }

  /* @media (min-width: 675px) and (max-width: 1023px) {

} */

@media (min-width: 1024px) {
    .why-us {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
   
}

@media (max-width: 1023px) {
    .home .why-us-left {
        text-align: center;
        width:100%;
    }
    .why-us-right {
        width:fit-content;
    }

    .why-us-right-content {
        display: flex;
        justify-content: center;
    }
}

 @media (min-width: 470px) {
    .why-us-content-part {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}

@media (max-width: 469px) {
    .why-us-element {
        width:fit-content;
        margin: 10px;
        padding: 50px 30px 0 30px;
    }

    .why-us-right img, .why-us-element-text {
        width:100% !important;
    }
}