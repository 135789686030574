.request-form-wrapper {
    background: #1566adb3;
}

.request-form {
    color: #fff;
    padding: 100px 0;
}

.request-form-part {
    margin-bottom: 18px;
}

.request-form-title {
    font-size: 34px;
    font-weight: 700;
    line-height: 1.5;
}

.request-form-text {
    font-family: "Nunito Sans", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    max-width: 400px;
}

.request-form-element input, .request-form-element select, textarea {
    background: none;
    border: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    margin: 0 12px;
    color: #fff;
    width: 220px
}

.request-form-element select {
    padding-top: 20px;
}

option {
    color: #1566adf0;
    padding: 10px 0;
}



.request-form-element input:focus, .request-form-element select:focus, textarea:focus  {
    outline: none !important ;
}

::placeholder { 
    color: #fff;
    font-size: 16px;
  }

.quote-button {
    color: #1566adf0;
    padding: 18px 50px;
    width: 220px;
    margin: 0 12px;
}

.message-sent {
    color: rgb(178, 22, 22);
    font-weight: 600;
}


@media (min-width: 950px) {
    .request-form {
        padding-left: 50%;
    }
   
}

@media (max-width: 949px) {
    .request-form-wrapper {
        display: flex;
        justify-content: center;
    }
   
}

@media (min-width: 512px) {
    .request-form-part {
        display: flex;
        justify-content: flex-start;
    }

    .request-form-title {
        margin: 0 12px 25px 12px;
    }
    
    .request-form-text {
        margin: 0 12px 55px 12px;  
    } 
}

@media (max-width: 512px) {
    .request-form-wrapper {
        display: flex;
        justify-content: center;
    }

    .request-form-element input, .request-form-element select, textarea, .quote-button {
        width: 100%;
        margin: 0 auto;
    }

    .request-form-element input, .quote-button {
        margin-top: 25px;
    }
   
}


