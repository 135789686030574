/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.phone-absolute {
  position: fixed;
  padding: 3px;
  width: 55px;
  height: 55px;
  bottom: 40px;
  right: 40px;
  background-color: #021e3c;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 1;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.axis-vertical {
  display: none;
}

body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      /* opacity: 0; */
      margin-top: 10px;
  }
  100% {
    margin-top: 0;
      /* opacity: 1; */
  }
}