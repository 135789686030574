.why-us-element-subtext {
    font-size: 20px;
    line-height: 1.5;
    color: #233e62;
    font-weight: bolder;
    margin-top: 25px;
    padding-bottom: 10px;
}

.nemt .why-us-element-text {
    max-width: 500px;
    text-align: left;
}

.nemt .center-wrapper {
    display: flex;
    justify-content: center;
}

.nemt .why-us {
    padding-left: 30px;
    padding-right: 30px;
}

.nemt .why-us-left {
    margin-right: 30px;
}