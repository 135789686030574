.tips {
    padding:10px
}

.numnered {
padding-left: 20px
}


.terms-content-wrapper {
    display: flex;
    justify-content: center;
    margin: 100px 0;
}

.terms-content-title {
    font-size: 30px;
    line-height: 1.5;
    color: #233e62;
    font-weight: 600;
    margin-bottom: 30px;;
}

.terms-content-subtitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    color: #233e62;
    margin: 10px 0;
}

.terms-content-description {
    font-family: "Nunito Sans", Arial, sans-serif;
    background: #fff;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: gray;
}

.terms-content {
    padding: 15px;
}   

@media (min-width: 1024px) {
    .terms-content {
        max-width: 1000px;
    }
   
}

@media (min-width: 124px) and (max-width: 1023px) {
    .terms-content {
        max-width: 680px;
    }   
}
