.services {
    background: #e5e4f0;
    text-align: center;
}

.services-content-wrapper{
    display: flex;
    justify-content: center;
    padding: 50px 0 120px 0;
}

.services-content {
    width:1140px;
    /* display:flex; */
    justify-content: center;
    
}

.service-element img {
    width:100%;
    height: 275px;
    object-fit: cover;
}

.service-element {
    width: 30%;
    margin: 1.66%;
    text-align: left;
    border: 4px solid #021e3c;
    background: white;
}

.services-content .slider-button {
    display: flex;
    align-items: center;
    padding: 10px 18px;
}

.service-element-title {
    font-size: 20px;
    margin-bottom: 16px;
    line-height: 1.5;
    color: #000000;
    font-weight: 400;
}

.service-text {
    font-family: "Nunito Sans", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: gray;
}

.servies-title {
    font-size: 34px;
    font-weight: 600;
    line-height: 1.5;
    color: #233e62;
    padding-top: 115px;
    margin-bottom: 30px;
}

@media (min-width: 850px) {
  
    .service-content-part {
        display:flex;
        align-items: flex-start;
    }
    
}

@media (min-width: 600px) and (max-width: 849px) {
  

    .service-element {
        width: 60%;
        margin: 40px auto;

    }
    
}

@media (max-width: 599px) {  

    .service-element {
        width: 80%;
        margin: 40px auto;

    }
    
}