.contact-info {
    margin: 50px 0;
}

.contact-info-element {
    text-align: center;
    width: 250px;
    padding: 30px 0;
    margin: 20px 0;
}

.contac-info-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.8;
}

.contact-info-detail {
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: gray;
}

.contact-info-element svg {
    color: gray;
}

.contact-info-detail a {
    color: black;
}

.copntact-form-area-wrapper {
    display: flex;
    justify-content: center;
}

.contact-form {
    background: #fafafa;
    padding: 50px;
    padding-left: 30px;
}

.contact-form-description {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
    line-height: 1.5;
    color: #233e62;
    text-align: center;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin:0;
    margin-bottom: 20px;
    padding: 18px 0;
    padding-left: 20px;
    color:black;
}

.contact ::placeholder { 
    color: gray;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    /* padding-left: 10px; */
  }

.contact-form input:focus, .contact-form textarea:focus {
   outline: none;
   border: #434343 solid 1px;
}

.contact-form textarea {
    height: 150px;
    background: white;
}
.contact-form {
    margin-bottom: 200px;
}

@media (min-width: 700px) {
    .contact-info {
        display: flex;
        justify-content: center;
    }
    .contact-info-element {
        border-right: 1px solid rgba(0, 0, 0, 0.1);;
        border-left: 1px solid rgba(0, 0, 0, 0.1);;
    }
    .contact-form-area {
        width: 750px;
    }
}

@media (max-width: 699px) {
    .contact-info-element-wrapper {
        display: flex;
        justify-content: center;
    }

    .contact-form-description {
        padding: 0 20px;
    }

}