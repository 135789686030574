.email, .call, .request-button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-top {
    margin: 22px 0;;
}

.email-left, .call-left {
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
    line-height: 15px;
}

.call-text, .email-text {
    font-weight: 600;
 }

 .request-button {
    box-sizing: border-box;
    background-color: #f6bb18;
    border: 1px solid #f6bb18;
    font-size: 15px;
    padding: 8px 50px;
    border-radius: 22.5px;
    color: black;
 }
 
 .request-button:hover {
    background-color: white;
    color: #f6bb18;
 }

 .call {
    margin: 0 70px;
 }


 /* @media (min-width: 320px) and (max-width: 480px) {
    
  } */

  @media (min-width: 1024px) {
  
    .header-top, .logo{
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .logo {
        margin-right: 200px;
    }
    
  }

  @media (max-width: 1023px)  {
    .logo a {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 686px;
        margin-bottom: 30px;
    }
    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }

  @media (min-width: 675px)  {
    .header-left  {
        display: flex;
        align-items: center;
        justify-content: center;
    }   
}
    @media (max-width: 674px)  {
        .email, .call, .logo a, .request-button  {
            width:400px;
            margin:0;
            display: flex;
            align-items: center;
            justify-content: start;
            margin-bottom: 10px;
        } 

        .request-button {
            justify-content: center;
        }

        .logo a{
            margin-bottom: 30px;
        }
        .email, .call, .request-button-wrapper {
            margin-left: 30px;;
        }

        .email-wrapper, .call-wrapper, .request-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        ul {
            padding: 0;
        }
    }

  
  