.career .why-us-element-text {
    max-width: 500px;
}

.career .center-wrapper {
    display: flex;
    justify-content: center;
}

.career .why-us {
    padding-left: 30px;
    padding-right: 30px;
}